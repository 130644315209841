import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, message, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { reject } from 'lodash';
import React, { FC } from 'react';

interface IProps {
  description?: string;
  listType?: 'text' | 'picture';
  btnClassName?: string;
  fileList: UploadFile<any>[];
  onChange: (files: UploadFile<any>[]) => void;
  disabled: boolean;
}

const BulkUploadButton: FC<IProps> = ({ description, listType, btnClassName, fileList, onChange, disabled }) => {
  const beforeUpload = (file: UploadFile<any>) => {
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'text/plain'];

    if (file.type && !validTypes.includes(file.type)) {
      void message.error('You can only upload JPG/PNG/PDF file!');

      return false;
    }

    const isSizeLimit = file.size ? file.size / 1024 / 1024 < 7 : false;

    if (!isSizeLimit) {
      void message.error('Image must be smaller than 7MB!');

      return false;
    }

    onChange([...fileList, file]);

    return false;
  };

  const onRemove = (file: UploadFile<any>) => {
    const newFileList = reject(fileList, (f) => f === file);
    onChange(newFileList);
  };

  return (
    <Form.Item>
      <Upload
        listType={listType || 'text'}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        disabled={disabled}
        multiple
      >
        <Button className={btnClassName} icon={<UploadOutlined />}>
          {description || 'Upload'}
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default BulkUploadButton;
