import cx from 'classnames';
import { amplitudeLogger } from 'common/amplitude';
import { AMPLITUDE_ATTRIBUTE_KEYS, MESSENGER_EVENTS } from 'common/enums/amplitudeEvents';
import React, { FC } from 'react';

import { ReactComponent as CrossIcon } from './icons/cross.svg';
import { ReactComponent as SwapIcon } from './icons/swap.svg';
import styles from './OverlayContainer.module.less';
import { ESide } from './OverlayContainerContext.types';

interface IProps {
  onClose: () => void;
  onToggleSide?: () => void;
  side?: ESide;
  isMobile: boolean;
}

export const HeaderControls: FC<IProps> = ({ onClose, onToggleSide, isMobile, side }) => {
  const handleCloseClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    amplitudeLogger(MESSENGER_EVENTS.MESSENGER_CLOSED);
    onClose();
  };

  const handleToggleSideClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (side) {
      amplitudeLogger(MESSENGER_EVENTS.MESSENGER_SIDE_SWAPPED, {
        [AMPLITUDE_ATTRIBUTE_KEYS.SIDE]: side,
      });
    }

    onToggleSide?.();
  };

  return (
    <div
      className={cx({
        [styles.headerControlsBlock]: true,
      })}
    >
      {!isMobile && onToggleSide ? <SwapIcon onClick={handleToggleSideClick} /> : null}
      <CrossIcon onClick={handleCloseClick} />
    </div>
  );
};
