import { CalendarEvent, EventsTypeEnum, ProductionEventType } from '__generated__/types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, BadgeProps, Button, Popconfirm, Popover } from 'antd';
import routes from 'common/routes';
import moment from 'moment/moment';
import { LABELS_MAP } from 'pages/CalendarPage/CalendarPage.const';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './CustomCell.module.less';

interface Props {
  event: CalendarEvent;
  onDeleteEvent: (id: number) => void;
  onEditEvent: (event: ProductionEventType) => void;
}

const STATUS_MAP = {
  [EventsTypeEnum.PublicHolidayEvent]: 'success',
  [EventsTypeEnum.ProductionEvent]: 'error',
  [EventsTypeEnum.PoLeadtimeEvent]: 'processing',
};

export const CustomCell: FC<Props> = ({ event, onDeleteEvent, onEditEvent }) => {
  const startDate = moment(event.startDate);
  const endDate = moment(event.endDate);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    //blocking onSelect event bubbling
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="button"
      tabIndex={0}
    >
      <Popover
        trigger="click"
        open={open}
        onOpenChange={(value) => setOpen(value)}
        content={
          <div>
            <div className={s.content}>
              <Badge
                status={STATUS_MAP[event.type] as BadgeProps['status']}
                text={
                  event.type === EventsTypeEnum.PoLeadtimeEvent && 'providerOrderId' in event ? (
                    <a href={routes.order(event?.providerOrderId)} target="_blank" rel="noopener noreferrer">
                      {event.name}
                    </a>
                  ) : (
                    <b>{event.name}</b>
                  )
                }
              />
              {event.type === EventsTypeEnum.ProductionEvent && 'productionEventId' in event ? (
                <div className={s.buttonsContainer}>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setOpen(false);
                      onEditEvent(event);
                    }}
                  />
                  <Popconfirm
                    title={t('commonPhrases:areYouSure')}
                    onConfirm={() => onDeleteEvent(event.productionEventId)}
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              ) : null}
            </div>

            <div className={s.eventInfo}>
              <div>{t(LABELS_MAP[event.type])}</div>
              <div>
                {startDate.isSame(endDate)
                  ? `${startDate.date()} ${startDate.format('MMMM')}, ${startDate.year()}`
                  : `${startDate.date()} – ${endDate.date()} ${endDate.format('MMMM')}, ${endDate.year()}`}
              </div>
            </div>
          </div>
        }
      >
        <Badge status={STATUS_MAP[event.type] as BadgeProps['status']} className={s.eventBadge} text={event.name} />
      </Popover>
    </div>
  );
};
