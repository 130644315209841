import loadable, { DefaultComponent } from '@loadable/component';
import { PageLoader } from 'components/PageLoader';
import { createElement } from 'react';

export const lazyLoadPage = <Props>(
  loadFn: (props: Props) => Promise<DefaultComponent<Props>>,
  options?: Parameters<typeof loadable>[1],
) => {
  const load = async (props: Props): Promise<DefaultComponent<Props>> => {
    try {
      return await loadFn(props);
    } catch (e: unknown) {
      console.error(e);

      if (process.env.NODE_ENV !== 'development') {
        window.location.href = `${window.location.href}`;
      }

      // This line won't be called, but it is needed to make sure TS get correct types.
      return await loadFn(props);
    }
  };

  return loadable(load, { fallback: createElement(PageLoader), ...options });
};
