import { ContactContext, withAuth } from 'common/hoc';
import routes, { makeAbsoluteRoute } from 'common/routes';
import { FC, useContext } from 'react';

const RootRedirect: FC = () => {
  const contact = useContext(ContactContext);

  if (contact) {
    window.location.href = makeAbsoluteRoute(
      contact.provider.onboardingCompleted ? routes.offers : routes.profileOnboarding,
    );
  } else {
    window.location.href = makeAbsoluteRoute(routes.profileSignIn);
  }

  return null;
};

export default withAuth(RootRedirect);
