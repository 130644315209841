const ENV_CUSTOM_ENV = String(
  import.meta.env.REACT_APP_CUSTOM_NODE_ENV || process.env.REACT_APP_CUSTOM_NODE_ENV || 'development',
);
const FRONTEND_BASE_URL = String(import.meta.env.REACT_APP_BASE_URL || process.env['REACT_APP_BASE_URL'] || '');
const LOCAL_DEVELOPMENT = import.meta.env.ENV_CUSTOM_ENV === 'development' || ENV_CUSTOM_ENV === 'development';

const getENVVariable = (name: string): string => {
  const realValue = String(import.meta.env[name] || process.env[name] || '');

  if (ENV_CUSTOM_ENV === 'production') {
    return realValue;
  }

  if (typeof window === 'undefined') {
    return realValue;
  }

  try {
    const URL = window.location.href || '';
    const storageKey = `${FRONTEND_BASE_URL}__${name}`;

    const [, ...paramsList] = URL.split('?');
    const _params = new URLSearchParams((paramsList || []).join('?'));

    const URLParamValue = _params.get(name);

    if (typeof URLParamValue === 'string') {
      window.sessionStorage.setItem(storageKey, URLParamValue);

      return URLParamValue;
    }

    const sessionStorageParamValue = window.sessionStorage.getItem(storageKey);

    if (typeof sessionStorageParamValue === 'string') {
      return sessionStorageParamValue;
    }
  } catch (e: unknown) {
    console.error(e);
  }

  return realValue;
};

const APP_REGION = getENVVariable('REACT_APP_APP_REGION').toUpperCase();

const API_ENDPOINT = getENVVariable('REACT_APP_API_BASE_ENDPOINT');
const EU_HOST = getENVVariable('REACT_APP_EU_HOST');
const TR_HOST = getENVVariable('REACT_APP_TR_HOST');
const UK_HOST = getENVVariable('REACT_APP_UK_HOST');

const WS_ENDPOINT = getENVVariable('REACT_APP_WS_ENDPOINT');
const CDN_URL = getENVVariable('REACT_APP_CDN_URL');

const MSG_ENDPOINT = getENVVariable('REACT_APP_MSG_ENDPOINT');

const KNOWLEDGE_BASE_URL = getENVVariable('REACT_APP_KNOWLEDGE_BASE_URL');

const TEST_PROVIDERS_IDS = getENVVariable('REACT_APP_TEST_PROVIDERS_IDS');

const SENTRY_DSN = getENVVariable('REACT_APP_SENTRY_DSN');
const RELEASE = getENVVariable('REACT_APP_SENTRY_RELEASE');

export const ENV = {
  FRONTEND_BASE_URL,
  APP_REGION,
  API_ENDPOINT,
  WS_ENDPOINT,
  CDN_URL,
  MSG_ENDPOINT,
  TEST_PROVIDERS_IDS,
  LOCAL_DEVELOPMENT,
  KNOWLEDGE_BASE_URL,
  EU_HOST,
  TR_HOST,
  UK_HOST,
  SENTRY_DSN,
  RELEASE,
  ENV_CUSTOM_ENV,
};
