import { amplitudeSetContact } from 'common/amplitude';
import { ContactContext, withAuth } from 'common/hoc';
import { IWindow } from 'common/interfaces';
import { PrivateHeader } from 'components/Header/PrivateHeader';
import { PublicHeader } from 'components/Header/PublicHeader';
import React, { FC, useContext, useEffect } from 'react';

const winInterface = window as IWindow;

const HeaderComponent: FC = () => {
  const contact = useContext(ContactContext);

  // helphero
  useEffect(() => {
    if (!contact) {
      return;
    }

    amplitudeSetContact(contact);

    if (!winInterface.HelpHero) {
      console.error("Can't find and init helphero");

      return;
    }

    winInterface.HelpHero.identify(contact.provider.id, {
      id: contact.id,
      name: `${contact.firstName} ${contact.lastName}`,
      email: contact.email,
      providerId: contact.provider.id,
      providerState: contact.provider.state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  // getsitecontrol
  useEffect(() => {
    if (!contact) {
      return;
    }

    const getSiteParams = {
      id: contact.id,
      name: `${contact.firstName} ${contact.lastName}`,
      email: contact.email,
      providerId: contact.provider.id,
      providerState: contact.provider.state,
      providerCountry: contact.provider.billingAddress?.country,
      providerEmail: contact.provider.email,
    };

    if (!winInterface.gsc) {
      console.error("Can't find and init gsc");

      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    winInterface.gsc('params', getSiteParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  return contact ? <PrivateHeader contact={contact} /> : <PublicHeader />;
};

export const Header = withAuth(HeaderComponent);
