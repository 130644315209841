import { LocaleEnum } from 'common/config/locales';
import { getRegionDefaultLocale, getRegionSupportedLocales } from 'common/config/regions/utils';
import { useTranslation } from 'react-i18next';

const SUPPORTED_LOCALES = getRegionSupportedLocales();
const DEFAULT_LOCALE = getRegionDefaultLocale();

export const useCurrentLocale = (): LocaleEnum => {
  const { i18n } = useTranslation();
  const locale = i18n.language as LocaleEnum;

  return SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
};
