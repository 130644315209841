import { IWindow } from 'common/interfaces';

import { CURRENT_REGION } from './config/regions/config';
import { ContactType } from './queries/types';

export const SIGNUP_PAGE_VISIT = 'Registration | Form Page Visit';
export const SIGNUP_SUCCESS = 'Registration | Registration completed ';

export const SIGNIN_PAGE_VISIT = 'Sign In | Page Visit';
export const SIGNING_SUCCESS = 'Sign In | Success';
export const SIGNIN_UNSUCCESS = 'Sign In | Unsuccess';

export const ORDERS_PAGE_VISIT = 'My OrdersPage | Page Visit';
export const ORDERS_DOWNLOAD_TD = 'My OrdersPage | Download Tech Details';

export const FAQ_CLICK = 'FAQ & Help | Button click';

export const SUPPORT_PAGE_VISIT = 'Support | Page Visit';

export const PROFILE_PAGE_VISIT = 'Profile | Page Visit';

export const LOGOUT_CLICK = 'Log Out | Button Click';

export const INVOICES_PAGE_VISIT = 'Finance | Page Visit';
export const INVOICES_PROCESSED_VISIT = 'Finance | Processed Invoices section visit';
export const INVOICES_CREDIT_NOTE_VISIT = 'Finance | Credit Note section visit';
export const INVOICES_ADD_CLICK = 'Finance | Add Invoices Button Click';

export const CREDIT_NOTES_ADD_CLICK = 'Finance | Add Credit Note Button Click';

const winInterface = window as IWindow;

const APP_NAME = 'partnerAccount';

export const amplitudeSetContact = (contact: ContactType) => {
  if (!winInterface.amplitude || typeof winInterface.amplitude !== 'object') {
    console.error("Can't set contact for amplitude", contact);

    return;
  }

  if (contact && contact.provider.id) {
    winInterface.amplitude.getInstance().setUserId(contact.provider.id);

    const identify = new winInterface.amplitude.Identify()
      .set('ProviderState', contact.provider.state)
      .set('ProviderContactName', `${contact.firstName} ${contact.lastName}`)
      .set('ProviderContactEmail', contact.email)
      .set('ProviderDomain', CURRENT_REGION)
      .set('AppName', APP_NAME);
    winInterface.amplitude.getInstance().identify(identify);
  }
};

export const amplitudeLogger = (event: string, attrs = {}): void => {
  if (!winInterface.amplitude || typeof winInterface.amplitude !== 'object') {
    console.error("Can't find amplitude", event, attrs);

    return;
  }

  winInterface.amplitude.getInstance().logEvent(event, { ...attrs, erp_instance: CURRENT_REGION });
};
