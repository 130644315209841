import cn from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './PageTitle.module.less';

interface PageTitleProps {
  className?: string;
}
export const PageTitle: FC<PropsWithChildren<PageTitleProps>> = ({ className, children }) => {
  return <h1 className={cn(styles.container, className)}>{children}</h1>;
};
