/**
 * Parses search params string.
 * @param searchString search params string.
 * @returns Key-value map with from the parsed search params string.
 */
export const getURLSearchParamsMap = (searchString: string) => {
  try {
    const searchParams = new URLSearchParams(searchString);
    const paramsMap: Partial<Record<string, string>> = {};

    searchParams.forEach((value, key) => {
      paramsMap[key] = value;
    });

    return paramsMap;
  } catch (e: unknown) {
    console.error(`Error while parsing URL search string: ${searchString}\n`, e);
  }
};
