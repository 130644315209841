import { EMessengerAppName, IMessengerAppParamsByName, MessengerEventBus } from '@xometry/ui';

export enum ESide {
  Right = 'right',
  Left = 'left',
}

export enum EOverlayType {
  Messenger = 'messenger',
  None = 'none',
}

export type TOverlayContainerContextParam =
  | ({
      type: EOverlayType.Messenger;
    } & IMessengerAppParamsByName[EMessengerAppName.PARTNERS_MAIN])
  | {
      type: EOverlayType.None;
    };

export interface IOverlayContainerContext {
  context: TOverlayContainerContextParam;
  side?: ESide;
  openSideOverlay: (context: TOverlayContainerContextParam) => void;
  closeSideOverlay: () => void;
  toggleSide?: () => void;
  eventBus?: MessengerEventBus;
  setEventBus: (eventBus: IOverlayContainerContext['eventBus']) => void;
  hasBeenInitialized: boolean;
}
