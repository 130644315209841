import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMoment = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  return moment;
};
