import { EventsTypeEnum, ProductionEventType } from '__generated__/types';
import { Button, Calendar, Card, Col, Form, Input, Row, Spin } from 'antd';
import { useModal } from 'common/hooks/useModal';
import { useMoment } from 'common/hooks/useMoment';
import { useProductionEventsCreateMutation } from 'common/mutations/__generated__/productionEventsCreate';
import { useProductionEventsDeleteMutation } from 'common/mutations/__generated__/productionEventsDelete';
import { useProductionEventsUpdateMutation } from 'common/mutations/__generated__/productionEventsUpdate';
import { useCalendarEventsQuery } from 'common/queries/__generated__/calendarEvents';
import ContentWrapper from 'components/ContentWrapper';
import { Modal } from 'components/UI/Modal';
import { PageTitle } from 'components/UI/PageTitle';
import { RangePicker } from 'components/UI/RangePicker';
import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomCell } from './CustomCell';
import { CustomHeader } from './CustomHeader';

interface FormValues {
  id?: number;
  name: string;
  startDate: Moment;
  endDate: Moment;
}

export const CalendarPage: FC = () => {
  useMoment();
  const { t } = useTranslation();
  const { visibleModal: visibleCreateModal, toggleVisibility: toggleVisibilityCreateModal } = useModal();
  const [form] = Form.useForm<FormValues>();
  const [yearForQuery, setYearForQuery] = useState<number>(moment().year());
  const { data, loading, refetch } = useCalendarEventsQuery({
    variables: { year: yearForQuery },
  });
  const [productionEventsCreate, { loading: productionEventsCreateLoading }] = useProductionEventsCreateMutation();
  const [productionEventsUpdate, { loading: productionEventsUpdateLoading }] = useProductionEventsUpdateMutation();
  const [productionEventsDelete] = useProductionEventsDeleteMutation();
  const [filters, setFilters] = useState<EventsTypeEnum[]>([
    EventsTypeEnum.PublicHolidayEvent,
    EventsTypeEnum.ProductionEvent,
    EventsTypeEnum.PoLeadtimeEvent,
  ]);

  const [currentDate, setCurrentDate] = useState<Moment>(moment());

  const handleStartDateChange = (date: Moment | null) => {
    form.setFieldValue('startDate', date);
  };

  const handleEndDateChange = (date: Moment | null) => {
    form.setFieldValue('endDate', date);
  };

  const handleSaveHoliday = async () => {
    const values = form.getFieldsValue(true) as FormValues;
    const attributes = { name: values.name, startDate: values.startDate.format(), endDate: values.endDate.format() };

    if (values.id) {
      await productionEventsUpdate({ variables: { input: { id: values.id, ...attributes } } });
    } else {
      await productionEventsCreate({
        variables: {
          input: { name: values.name, startDate: values.startDate.format(), endDate: values.endDate.format() },
        },
      });
    }

    void refetch();
    toggleVisibilityCreateModal();
    form.resetFields();
  };

  const handleProductionEventDelete = async (id: number) => {
    const { data } = await productionEventsDelete({
      variables: {
        input: {
          id: id,
        },
      },
    });

    if (data?.productionEventsDelete?.success) {
      void refetch();
    }
  };

  const handleEditProductionHoliday = (event: ProductionEventType) => {
    form.setFieldsValue({
      id: event.productionEventId,
      name: event.name,
      startDate: moment(event.startDate),
      endDate: moment(event.endDate),
    });
    toggleVisibilityCreateModal();
  };

  const getCustomCell = (value: Moment) => {
    const eventsInCurrentDate = data?.calendarEvents.filter(
      (event) =>
        value.isBetween(moment(event.startDate), moment(event.endDate), 'day', '[]') && filters?.includes(event.type),
    );

    return (
      <div>
        {eventsInCurrentDate?.map((event) => (
          <CustomCell
            key={`${event.name}-${event.startDate}-${event.endDate}`}
            event={event}
            onDeleteEvent={(id) => void handleProductionEventDelete(id)}
            onEditEvent={handleEditProductionHoliday}
          />
        ))}
      </div>
    );
  };

  const handleCloseCreateEventModal = () => {
    form.resetFields();
    toggleVisibilityCreateModal();
  };

  const getCustomHeader = ({ value }: { value: Moment; onChange: (date: Moment) => void }) => {
    if (value.year() !== yearForQuery) {
      void refetch({ year: value.year() });
      setYearForQuery(value.year());
    }

    return (
      <CustomHeader
        date={value}
        onAddActionClick={toggleVisibilityCreateModal}
        onFiltersChange={setFilters}
        filters={filters}
        setCurrentDate={setCurrentDate}
      />
    );
  };

  const handleSelectDate = (date: Moment) => {
    setCurrentDate(date);
    form.setFieldsValue({ startDate: date, endDate: date });
    toggleVisibilityCreateModal();
  };

  return (
    <ContentWrapper>
      <PageTitle>{t('calendar:calendar')}</PageTitle>
      <Spin spinning={loading} size="large">
        <Card>
          <Calendar
            value={currentDate}
            headerRender={getCustomHeader}
            dateCellRender={getCustomCell}
            onSelect={handleSelectDate}
          />
        </Card>
      </Spin>
      <Modal
        width={330}
        open={visibleCreateModal}
        title={form.getFieldValue('id') ? t('calendar:editHoliday') : t('calendar:addHoliday')}
        footer={false}
        onCancel={handleCloseCreateEventModal}
        destroyOnClose
        confirmLoading={productionEventsCreateLoading || productionEventsUpdateLoading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label={t('calendar:holidayNameReason')} name="name">
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <RangePicker
                startDate={getFieldValue('startDate') as Moment}
                endDate={getFieldValue('endDate') as Moment}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
              />
            )}
          </Form.Item>
          <Row gutter={8} className="m-t-sm">
            <Col span={12}>
              <Button size="large" block onClick={handleCloseCreateEventModal}>
                {t('commonPhrases:cancel')}
              </Button>
            </Col>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Button
                    disabled={!getFieldValue('name') || !getFieldValue('startDate') || !getFieldValue('endDate')}
                    type="primary"
                    size={'large'}
                    block
                    onClick={() => void handleSaveHoliday()}
                  >
                    {t('commonPhrases:save')}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ContentWrapper>
  );
};
