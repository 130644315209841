import { getRegionCurrencySymbol } from 'common/config/regions/utils';
import { AddressProviderType } from 'common/queries/types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment, { Moment } from 'moment';

const NA = '–';
const DEFAULT_MONEY_VALUE = '0';

export const DATE_F = 'DD.MM.YYYY';
export const DATETIME_F = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT = 'HH:mm DD.MM.YYYY';

export const valueOrNa = (value: any): any => (isNil(value) || isEmpty(value) ? NA : value);

export const currency = (value: any, currency = getRegionCurrencySymbol()): string => {
  if (isNil(value)) return `${currency} ${DEFAULT_MONEY_VALUE}`;

  const parsedValue = parseFloat(String(value).replace(',', '.')).toFixed(2) || DEFAULT_MONEY_VALUE;

  const withSpaces = parsedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${currency} ${withSpaces}`;
};

export const percent = (value: any): string => {
  if (isNil(value)) return NA;

  const rounded = Math.round(parseFloat(String(value).replace(',', '.')) * 10) / 10;

  return `${rounded}%`;
};

export const roundNumber = (value: string | number | null | undefined): string => {
  if (isNil(value) || isNaN(Number(value))) return NA;

  return `${Math.round(parseFloat(String(value).replace(',', '.')) * 100) / 100}`;
};

export const mm2cm = (value: number): number => value / 1000;

export const parseDate = (date: string | Moment): Moment =>
  moment(date, ['YYYY-MM-DDTHH:mm:ssZ', 'DD-MM-YYYY HH:mm', 'YYYY-MM-DD HH:mm']);

export const dateFormat = (date?: string | Moment | null, format = DATE_F) => {
  if (!date) return NA;

  return parseDate(date).format(format);
};

export const dateFormatToBackend = (date: Moment): string => date.format(DATE_F);

export const dateDecoratedFormat = (date: string) => {
  if (!date) return NA;

  const value = moment(date);
  const diff = moment(new Date()).diff(value, 'days');

  switch (diff) {
    case 0:
      return `Today ${value.format('HH:mm')}`;
    case 1:
      return `Yesterday ${value.format('HH:mm')}`;
    default:
      return value.format(DATE_F);
  }
};

export const onlyLatinRule = {
  pattern: new RegExp(/^[a-z0-9\s-_.,]+$/i),
  message: 'Only latin letters and digits allowed',
};

export const dateHoursDiffFormat = (rawStart: string | Moment, rawEnd: string | Moment) => {
  if (!rawStart || !rawEnd) return NaN;

  const start = parseDate(rawStart);
  const end = parseDate(rawEnd);

  const diff = moment.duration(end.diff(start)).asHours();

  if (diff < 0) {
    return 0;
  }

  return diff;
};

export const dateDaysDiffFormat = (rawStart: string | Moment, rawEnd: string | Moment) => {
  if (!rawStart || !rawEnd) return NaN;

  const start = parseDate(rawStart);
  const end = parseDate(rawEnd);

  const diff = moment.duration(end.diff(start)).asDays();

  if (diff < 0) {
    return 0;
  }

  return diff;
};

export const dateTimeFormat = (date: string | Moment) => {
  if (!date) return NA;

  return parseDate(date).format('DD.MM.YYYY HH:mm');
};

export const shortName = (longName: string | null | undefined, length: number) => {
  if (!longName || longName.length <= length) {
    return longName;
  }

  const prefixLength = Math.round(length / 2);
  const tailLength = prefixLength;

  return `${longName.substr(0, prefixLength - 1)}…${longName.substr(longName.length - tailLength + 1, tailLength)}`;
};

export const address = (data: AddressProviderType | undefined) => {
  let result = '';

  if (!data) {
    return '-';
  }

  if (data.country) {
    result = `${result} ${data.country}`;
  }

  if (data.city) {
    result = `${result}, ${data.city}`;
  }

  if (data.address) {
    result = `${result}, ${data.address}`;
  }

  if (data.zip) {
    result = `${result}, ${data.zip}`;
  }

  return result;
};

export const pluralize = (one: string, many: string, count: number): string => (count > 1 ? many : one);

export const toPhoneLink = (phone: string) =>
  `tel:${phone}`
    .replace(/\s/g, '')
    .replace(/-/g, '')
    .replace(/\(?\)?/g, '');

export const toMailLink = (mail: string) => `mailto:${mail}`;

export const numberParser = (value: string | undefined): string | number => {
  if (value === undefined || value === null) return '0';

  let stringValue = String(value);

  if (stringValue.includes(',') && !stringValue.includes('.')) {
    stringValue = stringValue.replace(/,/g, '.');
  }

  return `${stringValue}`.replace(/[^0-9.-]/g, '');
};
