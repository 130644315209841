import { PAGES_NAMES_FOR_AMPLITUDE } from 'common/enums/amplitudeEvents';
import routes from 'common/routes';
import { useRouteMatch } from 'react-router-dom';

export const useCurrentPageForAmplitude = (): PAGES_NAMES_FOR_AMPLITUDE | undefined => {
  const match = useRouteMatch();

  switch (match.path) {
    case routes.offer(':id'):
      return PAGES_NAMES_FOR_AMPLITUDE.OFFER;
    case routes.offers:
      return PAGES_NAMES_FOR_AMPLITUDE.JOB_OFFERS;
    case routes.responses:
      return PAGES_NAMES_FOR_AMPLITUDE.RESPONSES;
    case routes.urgentOffers:
      return PAGES_NAMES_FOR_AMPLITUDE.URGENT;
    case routes.order(':id'):
      return PAGES_NAMES_FOR_AMPLITUDE.ORDER;
    case routes.orders:
      return PAGES_NAMES_FOR_AMPLITUDE.ORDERS;
    case routes.rfqs:
      return PAGES_NAMES_FOR_AMPLITUDE.RFQS;
    case routes.rfqsResponses:
      return PAGES_NAMES_FOR_AMPLITUDE.RFQS_RESPONSES;
    case routes.rfqOffer(':id'):
      return PAGES_NAMES_FOR_AMPLITUDE.RFQ_OFFER;
  }
};
