import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductionEventsDeleteMutationVariables = Types.Exact<{
  input: Types.ProductionEventsDeleteInput;
}>;


export type ProductionEventsDeleteMutation = { __typename?: 'Mutation', productionEventsDelete?: { __typename?: 'ProductionEventsDeletePayload', success: boolean } | null };


export const ProductionEventsDeleteDocument = gql`
    mutation productionEventsDelete($input: ProductionEventsDeleteInput!) {
  productionEventsDelete(input: $input) {
    success
  }
}
    `;
export type ProductionEventsDeleteMutationFn = Apollo.MutationFunction<ProductionEventsDeleteMutation, ProductionEventsDeleteMutationVariables>;

/**
 * __useProductionEventsDeleteMutation__
 *
 * To run a mutation, you first call `useProductionEventsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductionEventsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productionEventsDeleteMutation, { data, loading, error }] = useProductionEventsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductionEventsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProductionEventsDeleteMutation, ProductionEventsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductionEventsDeleteMutation, ProductionEventsDeleteMutationVariables>(ProductionEventsDeleteDocument, options);
      }
export type ProductionEventsDeleteMutationHookResult = ReturnType<typeof useProductionEventsDeleteMutation>;
export type ProductionEventsDeleteMutationResult = Apollo.MutationResult<ProductionEventsDeleteMutation>;
export type ProductionEventsDeleteMutationOptions = Apollo.BaseMutationOptions<ProductionEventsDeleteMutation, ProductionEventsDeleteMutationVariables>;