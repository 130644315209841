import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactsSignOutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ContactsSignOutMutation = { __typename?: 'Mutation', contactsSignOut?: { __typename?: 'SignOutPayload', signOut?: boolean | null } | null };


export const ContactsSignOutDocument = gql`
    mutation contactsSignOut {
  contactsSignOut(input: {}) {
    signOut
  }
}
    `;
export type ContactsSignOutMutationFn = Apollo.MutationFunction<ContactsSignOutMutation, ContactsSignOutMutationVariables>;

/**
 * __useContactsSignOutMutation__
 *
 * To run a mutation, you first call `useContactsSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactsSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactsSignOutMutation, { data, loading, error }] = useContactsSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useContactsSignOutMutation(baseOptions?: Apollo.MutationHookOptions<ContactsSignOutMutation, ContactsSignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactsSignOutMutation, ContactsSignOutMutationVariables>(ContactsSignOutDocument, options);
      }
export type ContactsSignOutMutationHookResult = ReturnType<typeof useContactsSignOutMutation>;
export type ContactsSignOutMutationResult = Apollo.MutationResult<ContactsSignOutMutation>;
export type ContactsSignOutMutationOptions = Apollo.BaseMutationOptions<ContactsSignOutMutation, ContactsSignOutMutationVariables>;