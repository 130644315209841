import { amplitudeLogger, LOGOUT_CLICK } from 'common/amplitude';
import { DeviceTypes } from 'common/enums/deviceTypes';
import { useDeviceTypeByWindowSize } from 'common/hooks/useDeviceType';
import { useContactsSignOutMutation } from 'common/mutations/__generated__/contactsSignOut';
import { ContactType } from 'common/queries/types';
import { clearAuthToken, clearRefreshToken, redirectToRoot } from 'common/requests';
import routes from 'common/routes';
import { MessengerHeaderButton } from 'components/Messenger/MessengerHeaderButton/MessengerHeaderButton';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import LogoSource from 'images/logo.svg';
import { ReactComponent as MobileMenuIcon } from 'images/mobileMenu.svg';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import { getSelectedKey } from './PrivateHeader.helpers';
import styles from './PrivateHeader.module.less';

interface AuthHeaderProps {
  contact: ContactType;
}

export const PrivateHeader: FC<AuthHeaderProps> = ({ contact }) => {
  const [userSignOut] = useContactsSignOutMutation();
  const location = useLocation();
  const selectedKeys = useMemo(() => getSelectedKey(location.pathname), [location]);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const windowSize = useDeviceTypeByWindowSize();

  useEffect(() => {
    if (windowSize !== DeviceTypes.MOBILE) {
      setIsOpenMobileMenu(false);
    }
  }, [windowSize]);

  const logout = async () => {
    amplitudeLogger(LOGOUT_CLICK);
    await userSignOut();
    clearAuthToken();
    clearRefreshToken();
    redirectToRoot();
  };

  return (
    <header className={styles.container}>
      <div className={styles.mobileHeaderElements}>
        <button className={styles.mobileMenuBtn} onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}>
          {isOpenMobileMenu ? <CloseIcon /> : <MobileMenuIcon />}
        </button>
        <Link to={routes.offers}>
          <img className={styles.logo} src={LogoSource} alt="logo" />
          &nbsp;for Partners
        </Link>
        <div className={styles.messengerBtn}>
          <MessengerHeaderButton className={styles.mobileMessengerBtn} />
        </div>
      </div>
      <DesktopMenu selectedKeys={selectedKeys} contact={contact} onLogout={() => void logout()} />
      <MobileMenu
        selectedKeys={selectedKeys}
        isOpen={isOpenMobileMenu}
        contact={contact}
        onLogout={() => void logout()}
        onClose={() => setIsOpenMobileMenu(false)}
      />
    </header>
  );
};
