import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { initialOverlayContainerContext, OverlayContainerContext } from './OverlayContainerContext';
import { EOverlayType, ESide, IOverlayContainerContext } from './OverlayContainerContext.types';

export const OverlayContainerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [contextState, setContextState] = useState<IOverlayContainerContext>(initialOverlayContainerContext);

  const openSideOverlay = useCallback<IOverlayContainerContext['openSideOverlay']>((context) => {
    setContextState((contextState) => ({ ...contextState, context, side: ESide.Right }));
  }, []);

  const closeSideOverlay = useCallback<IOverlayContainerContext['closeSideOverlay']>(() => {
    setContextState((contextState) => ({
      ...contextState,
      context: {
        type: EOverlayType.None,
      },
      side: undefined,
    }));
  }, []);

  const toggleSide = useCallback<NonNullable<IOverlayContainerContext['toggleSide']>>(() => {
    setContextState((contextState) => {
      let newSide: ESide | undefined = undefined;

      if (contextState.side) {
        if (contextState.side === ESide.Right) {
          newSide = ESide.Left;
        } else {
          newSide = ESide.Right;
        }
      }

      return {
        ...contextState,
        side: newSide,
      };
    });
  }, []);

  const setEventBus = useCallback((eventBus: IOverlayContainerContext['eventBus']) => {
    setContextState((contextState) => ({ ...contextState, eventBus }));
  }, []);

  useEffect(() => {
    setContextState((contextState) => ({
      ...contextState,
      openSideOverlay,
      closeSideOverlay,
      toggleSide,
      setEventBus,
      hasBeenInitialized: true,
    }));
  }, [closeSideOverlay, openSideOverlay, setEventBus, toggleSide]);

  return <OverlayContainerContext.Provider value={contextState}>{children}</OverlayContainerContext.Provider>;
};
