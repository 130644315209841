import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ContactFragment = { __typename?: 'Contact', id: number, firstName: string, lastName: string, email: string, position?: string | null, phone?: string | null, telegram?: string | null, viber?: string | null, whatsapp?: string | null, wechat?: string | null, preferredContactType?: string | null };

export const ContactFragmentDoc = gql`
    fragment contact on Contact {
  id
  firstName
  lastName
  email
  position
  phone
  telegram
  viber
  whatsapp
  wechat
  preferredContactType
}
    `;