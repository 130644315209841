import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './Modal.module.less';

export interface ModalProps extends AntModalProps {
  img?: ReactNode;
  title?: string;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ img, title, className, children, ...props }) => {
  return (
    <AntModal
      className={cn(styles.container, className, { [styles.isImg]: img })}
      cancelButtonProps={{ block: true, size: 'large' }}
      okButtonProps={{ block: true, size: 'large' }}
      {...props}
    >
      {img && <div className={styles.imgContainer}>{img}</div>}
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
    </AntModal>
  );
};
