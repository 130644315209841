import { useApolloClient } from '@apollo/react-hooks';
import { TEventBusEventCallback } from '@xometry/event-bus';
import {
  EMessengerChatSourceType,
  EMessengerEventBusEvents,
  IEventBusMessengerEvents,
  IEventBusMessengerOpenFilePreviewEventPayload,
  IMessageSource,
  MessengerEventBus,
} from '@xometry/ui';
import { FileFragment } from 'common/fragments/__generated__/file';
import { useDownload, useGroupDownload } from 'common/hooks/useDownload';
import { MessengerCounterDocument, MessengerCounterQuery } from 'common/queries/__generated__/messengerCounter';
import routes from 'common/routes';
import { useOverlayContainerContext } from 'components/OverlayContainer/OverlayContainer.hooks';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

const getRouteBySource = ({ sourceId, sourceType }: IMessageSource): string | undefined => {
  const routesMap: Partial<Record<EMessengerChatSourceType, string>> = {
    [EMessengerChatSourceType.PROVIDER_ORDER]: routes.order(sourceId),
    [EMessengerChatSourceType.JOB_OFFER]: routes.offer(sourceId),
    [EMessengerChatSourceType.RFQ_OFFER]: routes.rfqOffer(sourceId),
  };

  return routesMap[sourceType];
};

export const useMessengerEventBus = () => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const { eventBus, setEventBus } = useOverlayContainerContext();

  const [previewVisibility, setPreviewVisiblity] = useState(false);
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState<number>();
  const [filesPreview, setFilesPreview] = useState<FileFragment[]>([]);

  const downloader = useDownload();
  const groupDownload = useGroupDownload();

  const handleRedirectToDeal: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE
  > = useCallback(
    (messageSource) => {
      const route = getRouteBySource(messageSource);

      if (route) {
        history.push(route);
      }
    },
    [history],
  );

  const openPreview = useCallback(({ current, files }: IEventBusMessengerOpenFilePreviewEventPayload) => {
    setCurrentFilePreviewId(parseInt(files[current]?.fileId));
    setFilesPreview(
      files.map<FileFragment>((file) => ({
        id: +file.fileId,
        uuidId: file.fileUuid,
        name: file.fileName,
        mediumUrl: file.preview,
        largeUrl: file.bigPreview,
        downloadUrl: routes.downloadAttachedFile(file.fileUuid),
        context: 'default', // required, but no idea what for
        createdAt: '',
      })),
    );
    setPreviewVisiblity(true);
  }, []);

  const closePreview = useCallback(() => {
    setPreviewVisiblity(false);
    setFilesPreview([]);
    setCurrentFilePreviewId(0);
  }, []);

  const handleDownload: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.DOWNLOAD_FILE
  > = useCallback(
    async ({ fileId, fileName, onSuccess, onError }) => {
      try {
        await downloader(routes.downloadAttachedFile(fileId), fileName);

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch {
        if (typeof onError === 'function') {
          onError();
        }
      }
    },
    [downloader],
  );

  const handleDownloadGroupFiles: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.DOWNLOAD_FILES
  > = useCallback(
    async ({ uuidIds, fileName, onSuccess, onError }) => {
      try {
        await groupDownload(uuidIds, fileName);

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch {
        if (typeof onError === 'function') {
          onError();
        }
      }
    },
    [groupDownload],
  );

  const handleSetCounters: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.SET_COUNTER
  > = useCallback(
    (newCounter) => {
      // https://github.com/apollographql/apollo-client/issues/3909#issuecomment-449363099
      const queryData = cloneDeep(
        apolloClient.readQuery<MessengerCounterQuery>({ query: MessengerCounterDocument }),
      );

      const newSourcesCounter = newCounter.sources || [];

      if (queryData) {
        const mergedSourcesCounter = queryData?.messengerCounter.sources.map((sourceCount) =>
          Object.assign(
            sourceCount,
            newSourcesCounter.find(
              (newSourceCount) =>
                sourceCount.sourceType === newSourceCount.sourceType &&
                String(sourceCount.sourceId) === newSourceCount.sourceId,
            ),
          ),
        );

        const newMessengerCounter: MessengerCounterQuery['messengerCounter'] = {
          ...queryData.messengerCounter,
          ...newCounter,
          sources: mergedSourcesCounter,
        };

        apolloClient.writeQuery<MessengerCounterQuery>({
          query: MessengerCounterDocument,
          data: { messengerCounter: newMessengerCounter },
        });
      }
    },
    [apolloClient],
  );

  const addEventListeners = useCallback(
    (eventBus: MessengerEventBus) => {
      eventBus.addEventListener(EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE, handleRedirectToDeal);
      eventBus.addEventListener(EMessengerEventBusEvents.OPEN_FILE_PREVIEW, openPreview);
      eventBus.addEventListener(EMessengerEventBusEvents.DOWNLOAD_FILE, handleDownload);
      eventBus.addEventListener(EMessengerEventBusEvents.DOWNLOAD_FILES, handleDownloadGroupFiles);
      eventBus.addEventListener(EMessengerEventBusEvents.SET_COUNTER, handleSetCounters);
    },
    [handleDownload, handleDownloadGroupFiles, handleRedirectToDeal, handleSetCounters, openPreview],
  );

  const removeEventListeners = useCallback(
    (eventBus: MessengerEventBus) => {
      eventBus.removeEventListener(EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE, handleRedirectToDeal);
      eventBus.removeEventListener(EMessengerEventBusEvents.OPEN_FILE_PREVIEW, openPreview);
      eventBus.removeEventListener(EMessengerEventBusEvents.DOWNLOAD_FILE, handleDownload);
      eventBus.removeEventListener(EMessengerEventBusEvents.DOWNLOAD_FILES, handleDownloadGroupFiles);
      eventBus.removeEventListener(EMessengerEventBusEvents.SET_COUNTER, handleSetCounters);
    },
    [handleDownload, handleDownloadGroupFiles, handleRedirectToDeal, handleSetCounters, openPreview],
  );

  const setEventBusRef = useCallback(
    (newEventBus: MessengerEventBus) => {
      if (eventBus) {
        removeEventListeners(eventBus);
      }

      setEventBus(newEventBus);
      addEventListeners(newEventBus);

      return newEventBus;
    },
    [addEventListeners, eventBus, removeEventListeners, setEventBus],
  );

  return {
    setEventBusRef,
    previewVisibility,
    currentFilePreviewId,
    filesPreview,
    closePreview,
    sendEvent: eventBus?.sendEvent,
  };
};
