import { createConsumer } from '@rails/actioncable';
import { ENV } from 'common/constants/env';
import { getAuthToken } from 'common/requests';
import React, { FC, ReactNode, useMemo } from 'react';

import { ActionCableProviderContext } from './ActionCableProvider.context';
import { ActionCableProviderContextType } from './ActionCableProviderContext.types';

interface Props {
  children: ReactNode;
}

const PROVIDER_CONTACT_TOKEN = 'provider_contact_token';

export const ActionCableProvider: FC<Props> = (props) => {
  const { children } = props;
  const token = getAuthToken();

  const tokenParam = token ? `?${PROVIDER_CONTACT_TOKEN}=${token}` : '';
  const wsUrl = `${ENV.WS_ENDPOINT}${tokenParam}`;

  const contextValue: ActionCableProviderContextType = useMemo(() => {
    const cable = createConsumer(wsUrl);

    return {
      cable,
    };
  }, [wsUrl]);

  return <ActionCableProviderContext.Provider value={contextValue}>{children}</ActionCableProviderContext.Provider>;
};
