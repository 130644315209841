import { ENV } from './constants/env';

const mobileRoutes = {
  root: '/mobile',
  orderQcReport: (orderId: number | string) => `${mobileRoutes.root}/orders/${orderId}/qc_report`,

  uploadQCReport: (orderId: number | string): string =>
    `${ENV.CDN_URL}/partners/provider_orders/${orderId}/upload_qc_report`,
};

export default mobileRoutes;
