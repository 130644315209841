import { CurrencyEnum } from '__generated__/types';
import { LocaleEnum } from 'common/config/locales';
import { RegionData, Regions } from 'common/config/regions/types';
import { ENV } from 'common/constants/env';

import { ReactComponent as Icon } from './assets/eu.svg';

export const EURegionConfig: RegionData<Regions.EU> = {
  region: Regions.EU,
  currency: CurrencyEnum.Eur,
  defaultLocale: LocaleEnum.EN,
  supportedLocales: [LocaleEnum.EN],
  supportEmail: 'provider@xometry.eu',
  productionSupportEmail: 'production@xometry.eu',
  supportPhone: '+49-89-3803-4818',
  icon: Icon,
  host: ENV.EU_HOST,
};
