export enum JOB_BOARD_PAGE_EVENTS {
  JOB_BOARD_VISITED = 'job_board_visited',
  JOB_BOARD_LIST_ITEM_VIEWED = 'job_board_list_item_viewed',
  JOB_BOARD_FILTERS_SELECTED = 'job_board_filters_selected',
  JOB_FILES_DOWNLOADED = 'job_files_downloaded',
  PARTS_VIEWS_CAROUSEL_CLICKED = 'parts_views_carousel_clicked',
  CANT_TAKE_CLICKED = 'cant_take_clicked',
  CANT_TAKE_CONFIRMED = 'cant_take_confirmed',
  JOB_DETAILS_CLICKED = 'job_details_clicked',
  JOB_ID_CLICKED = 'job_id_clicked',
}

export enum SIMILAR_JOBS_EVENTS {
  SHOW_DETAILS_CLICKED = 'show_details_clicked',
  SIMILAR_JOBS_PREVIEW_VIEWED = 'similar_jobs_preview_viewed',
  SIMILAR_JOBS_LIST_ITEM_VIEWED = 'similar_jobs_list_item_viewed',
  SIMILAR_JOBS_LIST_PAGINATION_CHANGE = 'similar_jobs_list_pagination_change',
  VIEW_ALL_SIMILAR_JOBS_CLICKED = 'view_all_similar_jobs_clicked',
  SIMILAR_JOBS_CLICKED = 'similar_jobs_clicked',
}

export enum JOB_OFFER_PAGE_EVENTS {
  JOB_OFFER_PAGE_VISITED = 'job_offer_page_visited',
  PART_VIEWED = 'part_viewed',
  APPLY_FOR_JOB_CLICKED = 'apply_for_job_clicked',
  APPLICATION_RISKS_CONFIRMED = 'application_risks_confirmed',
  APPLICATION_SUBMITTED = 'application_submitted',
  HOW_TO_USE_CLICKED = 'how_to_use_clicked',
  COUNTEROFFER_CLICKED = 'counteroffer_clicked',
  COUNTEROFFER_SUBMITTED = 'counteroffer_submitted',
  REJECT_AND_CLONE_CLICKED = 'reject_and_clone_clicked',
  REJECT_CLICKED = 'reject_clicked',
  REJECT_AND_CLONE_SUBMITTED = 'reject_and_clone_submitted',
  REJECT_SUBMITTED = 'reject_submitted',
  PART_FILE_DOWNLOAD_CLICKED = 'part_file_download_clicked',
  ALL_JOB_FILES_DOWNLOAD_CLICKED = 'all_job_files_download_clicked',
}

export enum PREVIEW_FILES_EVENTS {
  PREVIEW_FILES_CLICKED = 'preview_files_clicked',
  PREVIEW_FILE_DOWNLOADED = 'preview_file_downloaded',
}

export enum RFQ_OFFER_PAGE_EVENTS {
  WILL_SUBMIT_QUOTE_24H = 'will_submit_quote_24H_clicked',
}

export enum PAGES_NAMES_FOR_AMPLITUDE {
  JOB_OFFERS = 'jobs',
  OFFER = 'job_offer',
  RESPONSES = 'my_responses',
  URGENT = 'urgent_jobs',
  ORDERS = 'orders',
  ORDER = 'order',
  RFQS = 'rfqs',
  RFQS_RESPONSES = 'rfq_responses',
  RFQ_OFFER = 'rfq_offer',
}

export enum SECTION_NAMES_FOR_AMPLITUDE {
  ORDER_CONFIRM_MODAL = 'order_confirm_modal',
  COUNTEROFFER_SUBMITTED_MODAL = 'counter_offer_submitted_modal',
  OFFER_PAGE_SIMILAR_JOBS_SECTION = 'offer_page_similar_jobs_section',
  SIMILAR_JOBS_MODAL = 'similar_jobs_modal',
}

/**
 * Amplitude messenger events
 */
export enum MESSENGER_EVENTS {
  MESSENGER_OPENED = 'MessengerOpened',
  MESSENGER_CLOSED = 'MessengerClosed',
  MESSENGER_SIDE_SWAPPED = 'MessengerSideSwapped',
}

/**
 * Entry points for MESSENGER_OPENED event.
 */
export enum MESSENGER_ENTRY_POINTS {
  DOCUMENT_PAGE = 'DocumentPage',
  DOCUMENTS_TABLE = 'DocumentsTable',
  DOCUMENTS_CARD = 'DocumentsCard',
  SUPPORT_PAGE = 'SupportPage',
  FOOTER = 'Footer',
  HEADER = 'Header',
}

/**
 * Keys for amplitude messenger event attributes
 */
export enum AMPLITUDE_ATTRIBUTE_KEYS {
  ENTRY_POINT = 'EntryPoint',
  SIDE = 'Side',
}
