import cn from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './ContentWrapper.module.less';

interface ContentWrapperProps {
  containerClassName?: string;
  isAdaptivePage?: boolean;
}
const ContentWrapper: FC<PropsWithChildren<ContentWrapperProps>> = ({
  children,
  containerClassName,
  isAdaptivePage = true,
}) => {
  return (
    <div
      className={cn(containerClassName, styles.container, {
        [styles.isNotAdaptivePage]: !isAdaptivePage,
      })}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
