import { useCallback } from 'react';

import { requestBuilder } from 'common/axios';
import routesApi from 'common/routes';

const downloadFile = (file: Blob, filename: string) => {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  link.onclick = (e) => {
    e.stopImmediatePropagation();
  };

  document.body.appendChild(link);

  link.click();
};

export const useDownload = () => (url: string, filename: string) => {
  const request = requestBuilder({ responseType: 'blob' });

  return request
    .get(url)
    .then((response) => {
      const file = new Blob([response.data] as BlobPart[]);
      downloadFile(file, filename);
    })
    .catch(() => {
      console.error("Can't download file :(");
    });
};

export const useGroupDownload = () => {
  const download = useCallback((uuidIds: string[], filename?: string) => {
    const request = requestBuilder({ responseType: 'blob' });
    const url = routesApi.downloadAttachedFiles(uuidIds, filename);

    return request
      .get(url)
      .then((response) => {
        const file = new Blob([response.data] as BlobPart[]);

        let resultFilename = `${filename || 'attachments'}.zip`;
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const parsedFilename = (response.headers['content-disposition'] as string)
            ?.split(';')[1]
            ?.split('filename=')[1]
            ?.replace(/['"]+/g, '');

          if (typeof parsedFilename === 'string') {
            resultFilename = parsedFilename;
          }
        } catch {
          console.error('Failed to parse the content-disposition header.');
        }

        downloadFile(file, resultFilename);
      })
      .catch(() => {
        console.error(`Can't download file :(`);
      });
  }, []);

  return download;
};
