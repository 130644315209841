import { clearStorage, createRedirectUrl, getAuthToken, isSystemPage } from 'common/requests';
import React, { createContext, FC } from 'react';
import { Redirect } from 'react-router-dom';

import { useCurrentContactQuery } from './queries/__generated__/currentContact';
import { ContactType } from './queries/types';

export const ContactContext = createContext<ContactType | undefined | null>(undefined);

export const withAuth = <WrappedComponentProps extends object>(WrappedComponent: FC<WrappedComponentProps>) => {
  return function withAuth(props: WrappedComponentProps) {
    if (isSystemPage()) {
      return null;
    }

    let contact: ContactType | undefined | null = undefined;

    if (getAuthToken()) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { loading, data } = useCurrentContactQuery();

      if (loading || !data) {
        return <></>;
      }

      contact = data.currentContact;
    }

    return (
      <ContactContext.Provider value={contact}>
        <WrappedComponent {...props} />
      </ContactContext.Provider>
    );
  };
};

export const forAuth = <WrappedComponentProps extends object>(WrappedComponent: FC<WrappedComponentProps>) => {
  return function forAuth(props: WrappedComponentProps) {
    if (isSystemPage()) {
      return null;
    }

    if (!getAuthToken()) {
      clearStorage();

      return <Redirect to={createRedirectUrl()} />;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, data } = useCurrentContactQuery();

    if (loading || !data) {
      return <></>;
    }

    const contact = data.currentContact;

    if (!contact) {
      return <Redirect to={createRedirectUrl()} />;
    }

    return (
      <ContactContext.Provider value={contact}>
        <WrappedComponent {...props} />
      </ContactContext.Provider>
    );
  };
};
