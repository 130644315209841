import { Layout } from 'antd';
import mobileRoutes from 'common/mobileRoutes';
import { NotFoundPage } from 'pages';
import QcReport from 'pages/mobile/Order/QcReport';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

export const Mobile: FC = () => (
  <Layout className="mobile-layout">
    <Layout.Content>
      <Switch>
        <Route exact path={mobileRoutes.orderQcReport(':id')} component={QcReport} />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout.Content>
  </Layout>
);
