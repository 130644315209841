import { ReactComponent as BgIcon } from 'images/lang/lang-bg-icon.svg';
import { ReactComponent as CnIcon } from 'images/lang/lang-cn-icon.svg';
import { ReactComponent as DeIcon } from 'images/lang/lang-de-icon.svg';
import { ReactComponent as ElIcon } from 'images/lang/lang-el-icon.svg';
import { ReactComponent as EnIcon } from 'images/lang/lang-en-icon.svg';
import { ReactComponent as EsIcon } from 'images/lang/lang-es-icon.svg';
import { ReactComponent as FrIcon } from 'images/lang/lang-fr-icon.svg';
import { ReactComponent as HuIcon } from 'images/lang/lang-hu-icon.svg';
import { ReactComponent as ItIcon } from 'images/lang/lang-it-icon.svg';
import { ReactComponent as PlIcon } from 'images/lang/lang-pl-icon.svg';
import { ReactComponent as RoIcon } from 'images/lang/lang-ro-icon.svg';
import { ReactComponent as TrIcon } from 'images/lang/lang-tr-icon.svg';
import React from 'react';

export const langIcons: Record<string, JSX.Element> = {
  en: <EnIcon />,
  de: <DeIcon />,
  fr: <FrIcon />,
  es: <EsIcon />,
  it: <ItIcon />,
  pl: <PlIcon />,
  tr: <TrIcon />,
  bg: <BgIcon />,
  cn: <CnIcon />,
  el: <ElIcon />,
  hu: <HuIcon />,
  ro: <RoIcon />,
};
