import noop from 'lodash/noop';
import { createContext } from 'react';

import { EOverlayType, IOverlayContainerContext } from './OverlayContainerContext.types';

export const initialOverlayContainerContext: IOverlayContainerContext = {
  context: {
    type: EOverlayType.None,
  },
  openSideOverlay: noop,
  closeSideOverlay: noop,
  setEventBus: noop,
  hasBeenInitialized: false,
};
export const OverlayContainerContext = createContext<IOverlayContainerContext>(initialOverlayContainerContext);
