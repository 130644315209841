import { CurrencyEnum } from '__generated__/types';
import { LocaleEnum } from 'common/config/locales';
import { RegionData, Regions } from 'common/config/regions/types';
import { ENV } from 'common/constants/env';

import { ReactComponent as Icon } from './assets/tr.svg';

export const TRRegionConfig: RegionData<Regions.TR> = {
  region: Regions.TR,
  currency: CurrencyEnum.Try,
  defaultLocale: LocaleEnum.TR,
  supportedLocales: [LocaleEnum.EN, LocaleEnum.TR],
  supportEmail: 'provider@xometry.com.tr',
  productionSupportEmail: 'production@xometry.com.tr',
  supportPhone: '+90 (212) 221 06 35',
  icon: Icon,
  host: ENV.TR_HOST,
};
