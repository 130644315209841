import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductionEventsUpdateMutationVariables = Types.Exact<{
  input: Types.ProductionEventsUpdateInput;
}>;


export type ProductionEventsUpdateMutation = { __typename?: 'Mutation', productionEventsUpdate?: { __typename?: 'ProductionEventsUpdatePayload', success: boolean } | null };


export const ProductionEventsUpdateDocument = gql`
    mutation productionEventsUpdate($input: ProductionEventsUpdateInput!) {
  productionEventsUpdate(input: $input) {
    success
  }
}
    `;
export type ProductionEventsUpdateMutationFn = Apollo.MutationFunction<ProductionEventsUpdateMutation, ProductionEventsUpdateMutationVariables>;

/**
 * __useProductionEventsUpdateMutation__
 *
 * To run a mutation, you first call `useProductionEventsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductionEventsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productionEventsUpdateMutation, { data, loading, error }] = useProductionEventsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductionEventsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductionEventsUpdateMutation, ProductionEventsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductionEventsUpdateMutation, ProductionEventsUpdateMutationVariables>(ProductionEventsUpdateDocument, options);
      }
export type ProductionEventsUpdateMutationHookResult = ReturnType<typeof useProductionEventsUpdateMutation>;
export type ProductionEventsUpdateMutationResult = Apollo.MutationResult<ProductionEventsUpdateMutation>;
export type ProductionEventsUpdateMutationOptions = Apollo.BaseMutationOptions<ProductionEventsUpdateMutation, ProductionEventsUpdateMutationVariables>;