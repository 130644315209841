import { IS_TR } from 'common/constants/contstants';

import { footerLinks } from './Footer.const';

interface Resource {
  title: string;
  url?: string;
  noTargetBlank?: boolean;
  onClick?: () => void;
}
const getFooterResources = (): Resource[] => {
  if (!IS_TR) {
    return [
      ...footerLinks,
      {
        title: 'footer:resourcesSection.privacySettings',
        noTargetBlank: true,
        onClick: () => {
          if (typeof UC_UI !== 'undefined') {
            UC_UI.showSecondLayer();
          }
        },
      },
    ];
  }

  return footerLinks;
};

export const resources = getFooterResources();
