import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ENV } from 'common/constants/env';
import { getAuthToken } from 'common/requests';

const apiEndpoint = ENV.API_ENDPOINT;

export const requestBuilder = (params: Partial<AxiosRequestConfig>): AxiosInstance =>
  axios.create({
    baseURL: apiEndpoint,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    ...params,
  });
