import styled from '@emotion/styled';

export const QCReportrWrapper = styled.div`
  margin: 16px;

  .form-header {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 0 0 16px 0;
  }
  .form-title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin: 0 0 16px 0;
  }
  .form-subtitle {
    font-size: 14px;
    text-align: center;
    margin: 0 0 16px 0;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-upload {
    display: block;
    .ant-btn {
      background-color: #f5faff;
      color: #1771f1;
      font-weight: 600;
      font-size: 16px;
      border: 1px solid #1771f1;
    }
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }

  .ant-upload-list-item-progress {
    padding 0 20px 0 0;
  }

  .ant-upload-list-item {
    background: #ffffff;
    padding: 9px;
    height: auto;
    font-size: 14px;
    border-radius: 4px;
  }
`;
