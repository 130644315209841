import { useCurrentContactQuery } from 'common/queries/__generated__/currentContact';
import { getURLSearchParamsMap } from 'common/utils/getURLSearchParamsMap';
import { getInitialMessengerParams } from 'common/utils/initialURLHashUtils';
import { useOverlayContainerContext } from 'components/OverlayContainer/OverlayContainer.hooks';
import { EOverlayType } from 'components/OverlayContainer/OverlayContainerContext.types';
import { useEffect, useRef, useState } from 'react';

/**
 * Utilises the URL hash.
 *
 * Currently, is used only for the initialization of the Message Center.
 *
 * Examples of hashes:
 * 1. #messenger=Empty
 * 2. #messenger=Chat&initialMessageId=496
 * 3. #messenger=Chat&sourceType=Deal::ProviderOrder&sourceId=318&dealId=3049&sourceTitle=PO-3049-318
 */

export const useInitialURLHash = () => {
  const { data } = useCurrentContactQuery();

  const fired = useRef(false);

  const isAuthorized = Boolean(data?.currentContact);

  const { openSideOverlay, hasBeenInitialized } = useOverlayContainerContext();

  const [hashParamsMap] = useState(() => {
    let hash = '';

    try {
      hash = window.location.hash.substring(1); // removing the first # character
    } catch (e: unknown) {
      console.error('Unable to read the hash from the location.\n', e);
    }

    return getURLSearchParamsMap(hash);
  });

  useEffect(() => {
    if (!isAuthorized || !hasBeenInitialized || fired.current || !hashParamsMap) {
      return;
    }

    fired.current = true;

    /**
     * Initialization of the Message Center by the hash.
     */
    if (hashParamsMap.messenger) {
      const initialParams = getInitialMessengerParams(hashParamsMap);

      if (initialParams) {
        openSideOverlay({
          type: EOverlayType.Messenger,
          ...initialParams,
        });

        try {
          if (window.location.hash) {
            window.location.hash = '';
          }
        } catch (e: unknown) {
          console.error('Unable to clear the hash.\n', e);
        }
      }
    }
  }, [hasBeenInitialized, hashParamsMap, isAuthorized, openSideOverlay]);
};
