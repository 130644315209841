import { useFeatureFlagsQuery } from 'common/queries/__generated__/featureFlags';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { FeatureFlagsContext, FeatureFlagsContextType } from './FeatureFlagsContext';

export const FeatureFlagsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data, loading } = useFeatureFlagsQuery();

  const contextValue = useMemo<FeatureFlagsContextType>(() => {
    if (loading) {
      return {
        flags: {},
        loaded: false,
      };
    }

    return {
      flags:
        data?.featureFlags.reduce<FeatureFlagsContextType['flags']>((acc, flag) => {
          if (flag.enabled) {
            acc[flag.name] = flag.enabled;
          }

          return acc;
        }, {}) || {},
      loaded: true,
    };
  }, [data, loading]);

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};
