import 'whatwg-fetch';

import routes, { makeAbsoluteRoute } from 'common/routes';
import { startsWith } from 'lodash';
import { stringify } from 'qs';

export const getAuthToken = () => {
  return window.localStorage.getItem('authToken') || '';
};

export const getRefreshToken = () => {
  return window.localStorage.getItem('refreshToken');
};

export const clearStorage = () => {
  window.localStorage.clear();
};

export const setRefreshToken = (rToken: string) => {
  window.localStorage.setItem('refreshToken', rToken);
};

export const clearRefreshToken = () => {
  window.localStorage.removeItem('refreshToken');
};

export const setAuthToken = (token: string) => {
  window.localStorage.setItem('authToken', token);
};

export const clearAuthToken = () => {
  window.localStorage.removeItem('authToken');
};

export const createRedirectUrl = (withFromQuery = true) => {
  const from = window.location.pathname;
  let query: string;

  if (!startsWith(from, routes.profileSignIn)) {
    query = withFromQuery && from && from !== '/' ? `?${stringify({ from })}${window.location.search}` : '';
  } else {
    query = window.location.search;
  }

  return `${routes.profileSignIn}${query}`;
};

export const redirectToLogin = (withFromQuery = true) => window.location.assign(createRedirectUrl(withFromQuery));
export const redirectToRoot = () =>
  (window.location.href = `${makeAbsoluteRoute(routes.root)}${window.location.search || ''}`);

export const isLoginPage = () => window.location.pathname.includes('sign_in');

export const isSystemPage = () =>
  !!window.location.pathname.match(/\/404$/) || !!window.location.pathname.match(/\/503$/);

export const redirectTo404 = () => {
  window.location.href = `${makeAbsoluteRoute(routes.page404)}${window.location.search || ''}`;
};

export const redirectTo503 = () => {
  window.location.href = `${makeAbsoluteRoute(routes.page503)}${window.location.search || ''}`;
};
