import { EMessengerEventBusEvents, EMessengerScreenName } from '@xometry/ui';
import cx from 'classnames';
import { amplitudeLogger } from 'common/amplitude';
import { AMPLITUDE_ATTRIBUTE_KEYS, MESSENGER_ENTRY_POINTS, MESSENGER_EVENTS } from 'common/enums/amplitudeEvents';
import { useMessengerCounter } from 'common/hooks/useMessengerCounter';
import { useMessengerEventBus } from 'common/hooks/useMessengerEventBus';
import { useOverlayContainerContext } from 'components/OverlayContainer/OverlayContainer.hooks';
import { EOverlayType } from 'components/OverlayContainer/OverlayContainerContext.types';
import React, { FC, useCallback } from 'react';

import { ReactComponent as MessengerIcon } from './icon.svg';
import styles from './MessengerHeaderButton.module.less';

interface IProps {
  className?: string;
}

const MessengerHeaderButtonComponent: FC<IProps> = ({ className = '' }) => {
  const { openSideOverlay, context } = useOverlayContainerContext();
  const { sendEvent } = useMessengerEventBus();
  const getCounter = useMessengerCounter();
  const unreadCount = getCounter();

  const handleClick = useCallback<React.MouseEventHandler>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (context.type === EOverlayType.None) {
        openSideOverlay({
          type: EOverlayType.Messenger,
          initialScreen: {
            name: EMessengerScreenName.EMPTY,
          },
        });
      } else {
        sendEvent?.(EMessengerEventBusEvents.REINITIALIZE_MC, {
          initialScreen: {
            name: EMessengerScreenName.EMPTY,
          },
        });
      }

      amplitudeLogger(MESSENGER_EVENTS.MESSENGER_OPENED, {
        [AMPLITUDE_ATTRIBUTE_KEYS.ENTRY_POINT]: MESSENGER_ENTRY_POINTS.HEADER,
      });
    },
    [context.type, openSideOverlay, sendEvent],
  );

  return (
    <button
      className={cx({
        [styles.messengerHeaderButton]: true,
        [className]: Boolean(className),
      })}
      onClick={handleClick}
    >
      <MessengerIcon />
      {unreadCount > 0 ? <div className={styles.counter}>{unreadCount > 99 ? '99+' : unreadCount}</div> : null}
    </button>
  );
};

export const MessengerHeaderButton = React.memo(MessengerHeaderButtonComponent);
