import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import { Button, Form as AntdForm, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import mobileRoutes from 'common/mobileRoutes';
import BulkUploadButton from 'components/UI/BulkUploadButton';

import { QCReportrWrapper } from './styled';

import 'whatwg-fetch';

export const QcReport: FC = () => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const orderId = parseInt(id, 10);

  const [form] = AntdForm.useForm();

  const onFinish = async () => {
    setUploadLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      formData.append('files[]', file as any);
    });

    const response = await fetch(mobileRoutes.uploadQCReport(orderId), {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      setFileList([]);
      setUploadLoading(false);

      void message.success('All your files are uploaded.');
    } else {
      setUploadLoading(false);

      void message.error('Sorry, but we can not upload your files in current moment.');
    }
  };

  return (
    <QCReportrWrapper>
      <AntdForm form={form} onFinish={() => void onFinish()} layout="vertical" preserve={false}>
        <div className="form-header">{t('order:orderEvents.qualityControl.provideProtocol')}</div>
        <div className="form-subtitle">
          <div className="text-left">Please upload the filled out Quality Control Protocol template here</div>
        </div>
        <BulkUploadButton
          description="Provide Quality Control"
          btnClassName="ant-btn-lg btn-block"
          fileList={fileList}
          onChange={setFileList}
          disabled={uploadLoading}
        />
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          disabled={!fileList.length}
          loading={uploadLoading}
          className="btn-block"
        >
          {t('commonPhrases:save')}
        </Button>
      </AntdForm>
    </QCReportrWrapper>
  );
};

export default withRouter(QcReport);
