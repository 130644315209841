import { createContext } from 'react';

export interface FeatureFlagsContextType {
  flags: Partial<Record<string, boolean>>;
  loaded: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  flags: {},
  loaded: false,
});
