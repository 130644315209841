import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactsRefreshTokenMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String']['input'];
}>;


export type ContactsRefreshTokenMutation = { __typename?: 'Mutation', contactsRefreshToken?: { __typename?: 'RefreshTokenPayload', accessToken?: string | null, refreshToken?: string | null } | null };


export const ContactsRefreshTokenDocument = gql`
    mutation contactsRefreshToken($refreshToken: String!) {
  contactsRefreshToken(input: {refreshToken: $refreshToken}) {
    accessToken
    refreshToken
  }
}
    `;
export type ContactsRefreshTokenMutationFn = Apollo.MutationFunction<ContactsRefreshTokenMutation, ContactsRefreshTokenMutationVariables>;

/**
 * __useContactsRefreshTokenMutation__
 *
 * To run a mutation, you first call `useContactsRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactsRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactsRefreshTokenMutation, { data, loading, error }] = useContactsRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useContactsRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<ContactsRefreshTokenMutation, ContactsRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactsRefreshTokenMutation, ContactsRefreshTokenMutationVariables>(ContactsRefreshTokenDocument, options);
      }
export type ContactsRefreshTokenMutationHookResult = ReturnType<typeof useContactsRefreshTokenMutation>;
export type ContactsRefreshTokenMutationResult = Apollo.MutationResult<ContactsRefreshTokenMutation>;
export type ContactsRefreshTokenMutationOptions = Apollo.BaseMutationOptions<ContactsRefreshTokenMutation, ContactsRefreshTokenMutationVariables>;