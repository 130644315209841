import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductionEventsCreateMutationVariables = Types.Exact<{
  input: Types.ProductionEventsCreateInput;
}>;


export type ProductionEventsCreateMutation = { __typename?: 'Mutation', productionEventsCreate?: { __typename?: 'ProductionEventsCreatePayload', success: boolean } | null };


export const ProductionEventsCreateDocument = gql`
    mutation productionEventsCreate($input: ProductionEventsCreateInput!) {
  productionEventsCreate(input: $input) {
    success
  }
}
    `;
export type ProductionEventsCreateMutationFn = Apollo.MutationFunction<ProductionEventsCreateMutation, ProductionEventsCreateMutationVariables>;

/**
 * __useProductionEventsCreateMutation__
 *
 * To run a mutation, you first call `useProductionEventsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductionEventsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productionEventsCreateMutation, { data, loading, error }] = useProductionEventsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductionEventsCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductionEventsCreateMutation, ProductionEventsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductionEventsCreateMutation, ProductionEventsCreateMutationVariables>(ProductionEventsCreateDocument, options);
      }
export type ProductionEventsCreateMutationHookResult = ReturnType<typeof useProductionEventsCreateMutation>;
export type ProductionEventsCreateMutationResult = Apollo.MutationResult<ProductionEventsCreateMutation>;
export type ProductionEventsCreateMutationOptions = Apollo.BaseMutationOptions<ProductionEventsCreateMutation, ProductionEventsCreateMutationVariables>;