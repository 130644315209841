import { useApolloClient } from '@apollo/client';
import { IMessengerChatSource } from '@xometry/ui';
import {
  MessengerCounterDocument,
  MessengerCounterQuery,
  useMessengerCounterQuery,
} from 'common/queries/__generated__/messengerCounter';
import { useActionCable } from 'core/app/ActionCableProvider/ActionCableProvider.hooks';
import { useCallback, useEffect } from 'react';

const WS_CHANNEL = 'Messenger::PartnerCounterChannel';

export const useMessengerCounter = () => {
  const client = useApolloClient();
  const { cable } = useActionCable();
  const { data: counterData } = useMessengerCounterQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      {
        channel: WS_CHANNEL,
      },
      {
        received(data: { counter: MessengerCounterQuery['messengerCounter'] }) {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Web socket message received:', data);
          }

          if (counterData) {
            const newMessengerCounter = {
              ...counterData.messengerCounter,
              ...data.counter,
            };

            client.writeQuery<MessengerCounterQuery>({
              query: MessengerCounterDocument,
              data: { messengerCounter: newMessengerCounter },
            });
          }
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [cable.subscriptions, client, counterData]);

  const getCounter = useCallback(
    (sourceParams?: Pick<IMessengerChatSource, 'sourceType' | 'sourceId'>): number => {
      if (sourceParams) {
        const { sourceType, sourceId } = sourceParams;

        return (
          counterData?.messengerCounter.sources.find(
            (counter) => counter.sourceType === sourceType && String(counter.sourceId) === sourceId,
          )?.unreadMessagesCount || 0
        );
      }

      return counterData?.messengerCounter.unreadMessages || 0;
    },
    [counterData?.messengerCounter.sources, counterData?.messengerCounter.unreadMessages],
  );

  return getCounter;
};
