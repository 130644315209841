import { getMonitoringClient } from 'core/monitoring/monitoring';
import { createRoot } from 'react-dom/client';

export const bootstrap = async (node?: HTMLElement | null) => {
  const monitoringClient = await getMonitoringClient();
  await monitoringClient.init();

  if (!node) {
    throw new Error('No root node provided!');
  }

  const root = createRoot(node);

  // TODO: We'll need this in fresh react router.
  // const basename = ENV_BASE_URL ? ENV_BASE_URL.replace(window.location.origin, '') : undefined;

  return {
    monitoringClient,
    root,
    // basename,
  };
};
