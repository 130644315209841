import {
  EMessengerAppName,
  EMessengerChatSourceType,
  EMessengerScreenName,
  IMessengerAppParamsByName,
} from '@xometry/ui';

const isSourceType = (sourceType: string | undefined): sourceType is EMessengerChatSourceType => {
  const tmpSourceType = sourceType as EMessengerChatSourceType | undefined;

  if (!tmpSourceType) {
    return false;
  }

  return Object.values(EMessengerChatSourceType).includes(tmpSourceType);
};

/**
 * Calculates initial params for the Message Center by URL hash params.
 * @param options Object with params from URL hash
 * @param t t function from i18n
 * @returns Messenger app initial params
 */
export const getInitialMessengerParams = (
  options: Partial<Record<string, string>>,
): IMessengerAppParamsByName[EMessengerAppName.CUSTOMERS_MAIN] | null => {
  const { messenger: messengerScreenName, initialMessageId, sourceType, sourceId, dealId, sourceTitle } = options;

  if (initialMessageId) {
    return {
      initialMessageId,
    };
  }

  if (
    messengerScreenName === EMessengerScreenName.CHAT &&
    isSourceType(sourceType) &&
    sourceId &&
    sourceTitle &&
    dealId
  ) {
    return {
      initialScreen: {
        name: EMessengerScreenName.CHAT,
        params: {
          chatSource: {
            id: sourceId,
            dealId,
            sourceType,
            sourceId,
            dealTitle: sourceTitle,
            title: sourceTitle,
          },
        },
      },
    };
  } else if (messengerScreenName === EMessengerScreenName.EMPTY) {
    return {
      initialScreen: {
        name: EMessengerScreenName.EMPTY,
      },
    };
  }

  return null;
};
