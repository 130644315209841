import 'moment/locale/tr';

import { ConfigProvider } from 'antd';
import { ANT_LOCALE_MAPPING } from 'common/constants/contstants';
import mobileRoutes from 'common/mobileRoutes';
import { FeatureFlagsContextProvider } from 'components/FeatureFlags/FeatureFlagsContextProvider';
import { OverlayContainerContextProvider } from 'components/OverlayContainer/OverlayContainerContextProvider';
import { ScrollTop } from 'components/UI/ScrollTop';
import moment from 'moment/moment';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Desktop } from './Desktop';
import { Middleware } from './Middleware';
import { Mobile } from './Mobile';
import { preloadRoutes } from './utils';

export const App: FC = () => {
  const { i18n } = useTranslation();
  const antLocale = ANT_LOCALE_MAPPING[i18n.language];

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const id = setTimeout(() => {
      void preloadRoutes();
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <ConfigProvider locale={antLocale}>
      <FeatureFlagsContextProvider>
        <OverlayContainerContextProvider>
          <Router key={i18n.language}>
            <ScrollTop />
            <Middleware>
              <Switch>
                <Route path={mobileRoutes.root} component={Mobile} />
                <Route component={Desktop} />
              </Switch>
            </Middleware>
          </Router>
        </OverlayContainerContextProvider>
      </FeatureFlagsContextProvider>
    </ConfigProvider>
  );
};
