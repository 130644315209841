import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloudDownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { HoopsViewer, HoopsViewerLoader } from '@xometry/ant-ui';
import { Button, Col, Row } from 'antd';
import { amplitudeLogger } from 'common/amplitude';
import { PREVIEW_FILES_EVENTS } from 'common/enums/amplitudeEvents';
import { useCurrentPageForAmplitude } from 'common/hooks/useCurrentPageForAmplitude';
import { AttachFileType } from 'common/queries/types';
import PdfViewer from 'components/PdfViewer';
import { Modal } from 'components/UI/Modal';
import { find, first, get, indexOf, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PreviewModal.module.less';

interface IProps {
  onClose: () => void;
  initFileId?: number;
  files: AttachFileType[];
  sourceId?: number;
  sourceType?: 'offer' | 'order' | 'rfqOffer';
}

export const PreviewModal: React.FC<IProps> = ({ initFileId, files, onClose, sourceId, sourceType }) => {
  const [currentFile, setCurrentFile] = useState<AttachFileType | undefined>(files[0]);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();
  const currentPage = useCurrentPageForAmplitude();

  const handleNext = () => {
    if (!files[currentIndex + 1]) {
      return;
    }

    setCurrentFile(files[currentIndex + 1]);
    setRotation(0);
    setScale(1);
  };

  const handlePrev = () => {
    if (!files[currentIndex - 1]) {
      return;
    }

    setCurrentFile(files[currentIndex - 1]);
    setRotation(0);
    setScale(1);
  };

  const downHandler = ({ key }: { key: string }) => {
    if (key === 'ArrowRight') {
      handleNext();
    }

    if (key === 'ArrowLeft') {
      handlePrev();
    }
  };

  useEffect(() => {
    setCurrentFile(find(files, { id: initFileId }) || first(files));
  }, [initFileId, files]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const escHandler = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (event.data.event_id === 'iframe_keydown' && event.data.code === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('message', escHandler);

    return () => {
      window.removeEventListener('message', escHandler);
    };
  });

  if (!currentFile) {
    return null;
  }

  const fileName = get(currentFile, 'name');
  const ext = fileName && (last(get(currentFile, 'name').split('.')) || '').toLowerCase();

  const isSCS = ext === 'scs';
  const isPDF = ext === 'pdf';

  const currentIndex = indexOf(files, currentFile);

  const titleElement = (
    <Row className={styles.title}>
      <Col xl={5} sm={5} xs={24} className={styles.titleControls}>
        <Button type="link" onClick={handlePrev} disabled={!files[currentIndex - 1]}>
          <ArrowLeftOutlined />
        </Button>
        {t('commonPhrases:files')}&nbsp;
        {currentIndex + 1}/{files.length}
        <Button type="link" onClick={handleNext} disabled={!files[currentIndex + 1]}>
          <ArrowRightOutlined />
        </Button>
      </Col>
      <Col xl={13} sm={13} xs={24} className={styles.titleControls}>
        <Button
          type="link"
          download
          target="_blank"
          href={currentFile.downloadUrl}
          onClick={() => {
            amplitudeLogger(PREVIEW_FILES_EVENTS.PREVIEW_FILE_DOWNLOADED, {
              file_id: currentFile?.id,
              source_id: sourceId,
              source_type: sourceType,
              current_page: currentPage,
              position: currentIndex,
            });
          }}
          className={styles.downloadBtn}
        >
          {currentFile.name}
          &nbsp;
          <CloudDownloadOutlined />
        </Button>
      </Col>
      <Col xl={6} sm={6} xs={24} className={styles.titleControls}>
        {!isSCS && !isPDF && (
          <>
            <Button type="link" onClick={() => setScale(scale === 1.5 ? 1 : 0.5)} disabled={isSCS || scale === 0.5}>
              <ZoomOutOutlined />
            </Button>
            <Button type="link" onClick={() => setScale(scale === 0.5 ? 1 : 1.5)} disabled={isSCS || scale === 1.5}>
              <ZoomInOutlined />
            </Button>
            <Button type="link" onClick={() => setRotation(rotation + 90)} disabled={isSCS}>
              <RotateRightOutlined />
            </Button>
            <Button type="link" onClick={() => setRotation(rotation - 90)} disabled={isSCS}>
              <RotateLeftOutlined />
            </Button>
          </>
        )}
      </Col>
    </Row>
  );

  return (
    <Modal visible width="1024px" footer={false} onCancel={() => onClose()} className="modal-preview">
      {titleElement}
      {isSCS && currentFile.largeUrl ? (
        <HoopsViewerLoader>
          <HoopsViewer url={currentFile.largeUrl} originalFilename={currentFile.name} />
        </HoopsViewerLoader>
      ) : null}
      {isPDF && currentFile.largeUrl ? <PdfViewer src={currentFile.largeUrl} /> : null}
      {!isSCS && !isPDF && currentFile.largeUrl ? (
        <div className="ant-image">
          <img
            src={currentFile.largeUrl}
            alt={currentFile.name}
            style={{ transform: `rotate(${rotation}deg) scale(${scale})` }}
          />
        </div>
      ) : null}
    </Modal>
  );
};
