import { useErrorBoundaryContext } from '@xometry/ui';
import { FC, useEffect } from 'react';

/** @deprecated import the page from 'src/pages' */
export const ErrorPage: FC = () => {
  const { state } = useErrorBoundaryContext();
  const { error } = state;

  useEffect(() => {
    console.error(error);
  }, [error]);

  // TODO: Crash screen!

  return null;
};
