import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarEventsQueryVariables = Types.Exact<{
  year: Types.Scalars['Int']['input'];
}>;


export type CalendarEventsQuery = { __typename?: 'Query', calendarEvents: Array<{ __typename?: 'ProductionEventType', type: Types.EventsTypeEnum, name: string, startDate: string, endDate: string, productionEventId: number } | { __typename?: 'ProviderOrderLeadtimeEventType', type: Types.EventsTypeEnum, name: string, startDate: string, endDate: string, providerOrderId: number } | { __typename?: 'PublicHolidayEventType', type: Types.EventsTypeEnum, name: string, startDate: string, endDate: string }> };


export const CalendarEventsDocument = gql`
    query calendarEvents($year: Int!) {
  calendarEvents(year: $year) {
    ... on ProviderOrderLeadtimeEventType {
      type
      name
      startDate
      endDate
      providerOrderId
    }
    ... on ProductionEventType {
      type
      name
      startDate
      endDate
      productionEventId
    }
    ... on PublicHolidayEventType {
      type
      name
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
      }
export function useCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;