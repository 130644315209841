import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import bgTranslations from './bg/translations.json';
import cnTranslations from './cn/translations.json';
import deTranslations from './de/translations.json';
import elTranslations from './el/translations.json';
import enTranslations from './en/translations.json';
import esTranslations from './es/translations.json';
import frTranslations from './fr/translations.json';
import huTranslations from './hu/translations.json';
import itTranslations from './it/translations.json';
import plTranslations from './pl/translations.json';
import roTranslations from './ro/translations.json';
import trTranslations from './tr/translations.json';

const resources = {
  en: enTranslations,
  de: deTranslations,
  es: esTranslations,
  pl: plTranslations,
  fr: frTranslations,
  it: itTranslations,
  el: elTranslations,
  bg: bgTranslations,
  ro: roTranslations,
  hu: huTranslations,
  cn: cnTranslations,
  tr: trTranslations,
};

export const languages = Object.keys(resources);

const options: InitOptions = {
  resources,
  detection: {
    order: ['htmlTag'],
  },
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
    nsMode: 'default',
  },
};

// eslint-disable-next-line import/no-named-as-default-member
void i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
