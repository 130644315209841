import { CURRENT_REGION } from 'common/config/regions/config';
import { Regions } from 'common/config/regions/types';

interface Links {
  termsAndConditions: string;
  NDA: string;
  privacyPolicy: string;
  contactsAndImprint: string;
  knowledgeBase: string;
  qualityControlTemplatePDF: string;
  qualityControlTemplateDOCX: string;
  qualityControlGuide: string;
  usingBankAccount: string;
  qualityGuideline: string;
  qualityInspectionTypes: string;
  allAboutStates: string;
  learnBtnVideo: string;
  rating: string;
  packagingGuide: string;
  paymentRules: string;
  invoiceTemplate: string;
  certificatesInfo: string;
}
const links: Record<Regions, Links> = {
  [Regions.EU]: {
    termsAndConditions: 'https://knowledgebase.xometry.eu/books/9-legal-documents/page/terms-and-conditions',
    NDA: 'https://knowledgebase.xometry.eu/books/9-legal-documents/page/non-disclosure-agreement-nda',
    privacyPolicy: 'https://xometry.eu/en/policy/',
    contactsAndImprint: 'https://xometry.eu/en/contacts/',
    knowledgeBase: 'https://knowledgebase.xometry.eu/',
    qualityControlTemplatePDF: 'https://drive.google.com/file/d/1vg3cRRLYO8_zWS0xlbdOjrB4LuS9vy-d/view',
    qualityControlTemplateDOCX: 'https://docs.google.com/document/d/14M8Ymd2tZhwCWaZSBf1ZTdrsawsCD8np',
    qualityControlGuide:
      'https://knowledgebase.xometry.eu/books/8-quality-control/page/quality-control-report-step-by-step',
    usingBankAccount:
      'https://knowledgebase.xometry.eu/books/3-non-eu-partners-main-instructions/page/using-a-personal-bank-account-or-account-of-another-company',
    qualityGuideline: 'https://knowledgebase.xometry.eu/books/quality-control/page/what-needs-to-be-checked',
    qualityInspectionTypes:
      'https://knowledgebase.xometry.eu/books/8-quality-control/page/quality-inspection-types-requirements',
    allAboutStates:
      'https://knowledgebase.xometry.eu/books/2-eu-partners-main-instructions/page/all-about-states-what-is-probation-what-is-trusted',
    learnBtnVideo: 'https://www.youtube.com/embed/YigzCbTBu_E',
    rating: 'https://knowledgebase.xometry.eu/books/2-eu-partners-main-instructions/page/what-is-rating',
    packagingGuide: 'https://knowledgebase.xometry.eu/books/7-packaging-guide',
    paymentRules:
      'https://knowledgebase.xometry.eu/books/2-eu-partners-main-instructions/page/payment-and-invoicing-rules',
    invoiceTemplate: 'https://knowledgebase.xometry.eu/books/3-non-eu-partners-main-instructions/page/invoice-template',
    certificatesInfo: 'https://knowledgebase.xometry.eu/books/8-quality-control/chapter/certificates',
  },
  [Regions.TR]: {
    termsAndConditions:
      'https://knowledgebase.xometry.com.tr/books/7-yasal-dokumanlar/chapter/tedarikci-cerceve-sozlesmesi',
    NDA: 'https://knowledgebase.xometry.com.tr/books/7-yasal-dokumanlar/chapter/gizlilik-sozlesmesi',
    privacyPolicy: 'https://xometry.com.tr/tr/gizlilik/',
    contactsAndImprint: 'https://xometry.com.tr/tr/iletisim/',
    knowledgeBase: 'https://knowledgebase.xometry.com.tr/',
    qualityControlTemplatePDF:
      'https://ac-landing-pages-user-uploads-production.s3.amazonaws.com/0000088978/ac450792-7835-4a38-b009-93936262683f.pdf',
    qualityControlTemplateDOCX: 'https://docs.google.com/document/d/1CbJTwvyAXkbq-z819kBq9X0Hz24-K0Jc',
    qualityControlGuide:
      'https://knowledgebase.xometry.com.tr/books/5-kalite-kontrolu/page/sanal-kalite-kontrolu-vqc-nedir-ve-ne-yapmaliyim',
    usingBankAccount:
      'https://knowledgebase.xometry.eu/books/3-non-eu-partners-main-instructions/page/using-a-personal-bank-account-or-account-of-another-company',
    qualityGuideline:
      'https://knowledgebase.xometry.com.tr/books/5-kalite-kontrolu/page/neyin-kontrol-edilmesi-gerekiyor',
    qualityInspectionTypes:
      'https://knowledgebase.xometry.eu/books/8-quality-control/page/quality-inspection-types-requirements',
    allAboutStates:
      'https://knowledgebase.xometry.eu/books/2-eu-partners-main-instructions/page/all-about-states-what-is-probation-what-is-trusted',
    learnBtnVideo: 'https://www.youtube.com/embed/AixoBx49QYc',
    rating: 'https://knowledgebase.xometry.com.tr/books/2-profiliniz/page/genel-degerlendirmepuanlama-nedir',
    packagingGuide: 'https://knowledgebase.xometry.com.tr/books/4-paketleme-ve-kargo',
    paymentRules:
      'https://knowledgebase.xometry.com.tr/books/6-odeme-ve-faturalandirma-kurallari/page/odeme-ve-faturalandirma-kurallari',
    invoiceTemplate: 'https://knowledgebase.xometry.com.tr/books/5-kalite-kontrolu/page/kalite-kontrol-sablonu',
    certificatesInfo: 'https://knowledgebase.xometry.com.tr/books/5-kalite-kontrolu/chapter/sertifikalar',
  },
  [Regions.UK]: {
    termsAndConditions: 'https://knowledgebase.xometry.uk/books/7-legal-documents/page/terms-and-conditions',
    NDA: 'https://knowledgebase.xometry.uk/books/7-legal-documents/page/non-disclosure-agreement-nda',
    privacyPolicy: 'https://xometry.uk/policy/',
    contactsAndImprint: 'https://xometry.uk/contacts/',
    knowledgeBase: 'https://knowledgebase.xometry.uk/',
    qualityControlTemplatePDF: 'https://drive.google.com/file/d/1vg3cRRLYO8_zWS0xlbdOjrB4LuS9vy-d/view',
    qualityControlTemplateDOCX: 'https://docs.google.com/document/d/14M8Ymd2tZhwCWaZSBf1ZTdrsawsCD8np',
    qualityControlGuide:
      'https://knowledgebase.xometry.uk/books/4-quality-control/page/what-is-virtual-quality-control-vqc-and-what-should-i-do',
    usingBankAccount:
      'https://knowledgebase.xometry.uk/books/5-faq-for-partners/page/using-a-personal-bank-account-or-account-of-another-company',
    qualityGuideline: 'https://knowledgebase.xometry.uk/books/4-quality-control/page/what-needs-to-be-checked',
    qualityInspectionTypes:
      'https://knowledgebase.xometry.uk/books/4-quality-control/page/quality-inspection-types-requirements',
    allAboutStates:
      'https://knowledgebase.xometry.uk/books/2-using-xometry/page/all-about-states-what-is-probation-what-is-trusted ',
    learnBtnVideo: 'https://www.youtube.com/embed/k2yknHtPwf4',
    rating: 'https://knowledgebase.xometry.uk/books/2-using-xometry/page/what-is-rating',
    packagingGuide: 'https://knowledgebase.xometry.uk/books/3-packaging-guide',
    paymentRules: 'https://knowledgebase.xometry.uk/books/2-using-xometry/chapter/payment-and-invoicing',
    invoiceTemplate: 'https://knowledgebase.xometry.uk/books/2-using-xometry/page/invoice-credit-note-template',
    certificatesInfo: 'https://knowledgebase.xometry.uk/books/4-quality-control/chapter/certificates',
  },
};

export const regionLinks = links[CURRENT_REGION];
