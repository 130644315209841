import { EMessengerChatSourceType, IMessengerChatSource } from '@xometry/ui';
import cn from 'classnames';
import { amplitudeLogger } from 'common/amplitude';
import { AMPLITUDE_ATTRIBUTE_KEYS, MESSENGER_ENTRY_POINTS, MESSENGER_EVENTS } from 'common/enums/amplitudeEvents';
import { useOpenMessenger } from 'common/hooks/useOpenMessenger';
import { useCurrentProviderQuery } from 'common/queries/__generated__/currentProvider';
import { ReactComponent as MessengerIcon } from 'images/message-center-outlined.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import s from './GeneralChatButton.module.less';

interface Props {
  entryPoint?: MESSENGER_ENTRY_POINTS;
  className?: string;
}

export const GeneralChatButton: FC<Props> = ({ entryPoint, className }) => {
  const { t } = useTranslation();
  const { loading, data } = useCurrentProviderQuery({
    fetchPolicy: 'cache-and-network',
  });
  const openMessenger = useOpenMessenger();

  const { currentProvider } = data || {};

  const handleClick = () => {
    if (!currentProvider) {
      return;
    }

    const chatSource: IMessengerChatSource = {
      id: String(currentProvider.id),
      title: `${t('support:generalChat')}`,
      sourceType: EMessengerChatSourceType.PARTNER,
      sourceId: String(currentProvider.id),
    };

    openMessenger(chatSource);

    if (entryPoint) {
      amplitudeLogger(MESSENGER_EVENTS.MESSENGER_OPENED, {
        [AMPLITUDE_ATTRIBUTE_KEYS.ENTRY_POINT]: entryPoint,
        sourceType: EMessengerChatSourceType.PARTNER,
      });
    }
  };

  return (
    <button className={cn(className, s.generalChatButton)} onClick={handleClick} disabled={loading || !currentProvider}>
      <MessengerIcon />
      {t('support:generalChat')}
    </button>
  );
};
