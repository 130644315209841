import { useCallback, useState } from 'react';

export const useModal = () => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const onOpenModal = useCallback(() => setVisibleModal(true), []);
  const onCloseModal = useCallback(() => setVisibleModal(false), []);
  const toggleVisibility = useCallback(() => {
    setVisibleModal((openModal) => !openModal);
  }, []);

  return { visibleModal, setVisibleModal, onOpenModal, onCloseModal, toggleVisibility };
};
